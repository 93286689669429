.product-thumbnail .full-background {
    height: 15vh !important;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
    transition: filter 0.5s ease;
}

.product-thumbnail:hover .full-background {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 1;
}

.product-thumbnail .card {
    height: 20vh;
}

@media (min-width: 768px) {
    .product-thumbnail .full-background {
        height: 20vh !important;
    }

    .product-thumbnail .card {
        height: 25vh;
    }
}

@media (min-width: 1400px) {
    .product-thumbnail {
        flex: 0 0 12.5%;
        width: 12.5%;
    }

    .product-thumbnail .full-background {
        height: 15vh !important;
    }

    .product-thumbnail .card {
        height: 25vh;
    }
}